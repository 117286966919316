import React from 'react';
import { Link } from 'react-router-dom';
import first from './images/boisson/first.jpg';
import second from './images/boisson/second.jpg';
import menu2 from './images/boisson/menu2.jpg';
import menu3 from './images/boisson/menu3.jpg';
import menu4 from './images/boisson/menu4.jpg';
import menu5 from './images/boisson/menu5.jpg';
import menu6 from './images/boisson/menu6.jpg';
import menu7 from './images/boisson/menu7.jpg';
import menu8 from './images/boisson/menu8.jpg';

function Boisson() {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        background: 'black',
        overflow: 'hidden',
    };

    const navbarStyle = {
        position: 'fixed',
        top: '0',
        width: '100%',
        padding: '10px',
        background: 'black',
        color: '#fff',
        textAlign: 'center',
        zIndex: '1000',
    };

    const contentStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: '60px',
        overflowY: 'scroll',
        scrollSnapType: 'y mandatory',
        height: 'calc(100% - 60px)',
    };

    const imageContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        scrollSnapAlign: 'start',
    };

    const imageStyle = {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
    };

    const homeButtonStyle = {
        position: 'absolute',
        top: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        cursor: 'pointer',
        padding: '10px',
        background: '#fff',
        color: 'black',
        borderRadius: '5px',
        border: '2px solid #996515',
        outline: 'none',
        fontWeight: 'bold',
    };


    return (
        <div style={containerStyle}>
            <div style={navbarStyle}>
                <Link to="/">
                    <button style={homeButtonStyle}>Retour accueil</button>
                </Link>
            </div>
            <div style={contentStyle}>
                <div style={imageContainerStyle}>
                    <img src={first} alt="first" style={imageStyle} />
                </div>
                <div style={imageContainerStyle}>
                    <img src={second} alt="second" style={imageStyle} />
                </div>
                <div style={imageContainerStyle}>
                    <img src={menu2} alt="menu2" style={imageStyle} />
                </div>
                <div style={imageContainerStyle}>
                    <img src={menu3} alt="menu3" style={imageStyle} />
                </div>
                <div style={imageContainerStyle}>
                    <img src={menu4} alt="menu4" style={imageStyle} />
                </div>
                <div style={imageContainerStyle}>
                    <img src={menu5} alt="menu5" style={imageStyle} />
                </div>
                <div style={imageContainerStyle}>
                    <img src={menu6} alt="menu6" style={imageStyle} />
                </div>
                <div style={imageContainerStyle}>
                    <img src={menu7} alt="menu7" style={imageStyle} />
                </div>
                <div style={imageContainerStyle}>
                    <img src={menu8} alt="menu8" style={imageStyle} />
                </div>
            </div>
        </div>
    );
}

export default Boisson;


