import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';

function Home() {
  return (
    <div className="background-container">
      <div className="menu-choices">
        <Link to="/menu-du-semaine" className="menu-link menu-semaine">
          MENU SEMAINE
        </Link>
        <Link to="/menu-de-weekend" className="menu-link menu-weekend">
          MENU WEEKEND
        </Link>
        <Link to="/menu-boisson" className="menu-link menu-boisson">
          MENU BOISSON
        </Link>
      </div>
    </div>
  );
}

export default Home;
