import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Semaine from './components/Semaine';
import Weekend from './components/Weekend';
import Boisson from './components/Boisson';
import AudioPlayer from './components/AudioPlayer';

function App() {
  return (
    <Router>
      <div>
        <AudioPlayer/>
        <Routes>
          <Route path="/menu-du-semaine" element={<Semaine />} />
          <Route path="/menu-de-weekend" element={<Weekend />} />
          <Route path="/menu-boisson" element={<Boisson />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;


