import React, { useState } from "react";
import audioFile from "./AudioPlayer.mp3";
import "./AudioPlayer.css";

function AudioPlayer() {
    const [audio] = useState(new Audio(audioFile));
    const [playing, setPlaying] = useState(false);

    const togglePlay = () => {
        if (playing) {
            audio.pause();
        } else {
            audio.play().catch(error => console.error("Error attempting to play audio:", error));
        }
        setPlaying(!playing);
    };

    return (
        <div>
            <button onClick={togglePlay} className="button">{playing ? "Pause" : "Play"} Music</button>
        </div>
    );
}

export default AudioPlayer;
